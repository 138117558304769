import { template as template_2b2f46af30dd45c98bed679a5c86aa7e } from "@ember/template-compiler";
const FKControlMenuContainer = template_2b2f46af30dd45c98bed679a5c86aa7e(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
