import { template as template_c2b053f5c2014fa5b5564704fc2f43b8 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const OpLikesCell = template_c2b053f5c2014fa5b5564704fc2f43b8(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="op_likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default OpLikesCell;
