import { template as template_c35909df2c3b41a3b6e75ae7c1ff9c41 } from "@ember/template-compiler";
const WelcomeHeader = template_c35909df2c3b41a3b6e75ae7c1ff9c41(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
