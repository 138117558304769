import { template as template_ed0a422e3fa6498d96cfa55f55d94ace } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BackButton = template_ed0a422e3fa6498d96cfa55f55d94ace(`
  <DButton
    @action={{@onGoBack}}
    @label="topic.timeline.back"
    @title="topic.timeline.back_description"
    class="btn-primary btn-small back-button"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BackButton;
